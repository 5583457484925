'use client';

import { useGetProfileInfoQuery } from '@akinon/next/data/client/account';
import { useLocalization } from '@akinon/next/hooks';
import LactoseFreeIcon from '@theme/assets/icons/badge-lactose-free.svg';
import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useEffect, useMemo, useState } from 'react';

const Badge = (props) => {
  const { t } = useLocalization();
  const { showBadges, theme, selectedProduct } = props;
  const { data: profileData } = useGetProfileInfoQuery();
  const [isVynamicsAccepted, setIsvynamicsAccepted] = useState(false);

  useEffect(() => {
    if (profileData?.attributes) {
      const { logged_ip, ...otherAttributes } = profileData.attributes;
      const allTrue = Object.values(otherAttributes).every(
        (value) => value === true
      );
      if (allTrue) {
        setIsvynamicsAccepted(true);
      }
    }
  }, [profileData]);

  return (
    <>
      {showBadges && isVynamicsAccepted && (
        <div
          className={clsx(
            'absolute flex items-center justify-center gap-1 rounded-full border border-purple bg-purple bg-opacity-50 px-1.5 py-1',
            {
              'bottom-10 right-2 hidden': theme !== 'express',
              'bottom-10 right-2 h-fit lg:!top-2 lg:right-11':
                theme == 'express'
            }
          )}
        >
          <Image
            src="/akn-happiness.svg"
            height={14}
            width={15}
            alt={`lacoste-icon`}
          />
          <span className="text-xs font-semibold text-purple">2x</span>
        </div>
      )}
    </>
  );
};

export const TopChoiceBadge = ({data}) => {
  if (!data) return <></>;

  return (
    <>
      {' '}
      {data?.attributes?.badge_text_left && (
        <div className="z-10 absolute top-0 rounded-br-[4px] rounded-tl-2xl bg-[#E1F2EB] px-2.5 py-[7px] text-sm font-semibold md:px-2 md:py-1.5 md:text-xs md:font-bold">
          {data.attributes.badge_text_left}
        </div>
      )}
    </>
  );
};

export const Badges = (props) => {
  const session = useSession();
  const { t } = useLocalization();
  const { showBadges, theme, selectedProduct } = props;

  if (!session?.data?.user)
    return (
      <>
        {showBadges && ( //Statik, attribute'a bağlanıp dinamik hale getirilebilir.
          <>
            <div className="bottom-2.5 mt-1.5 hidden pb-0.5 text-left lg:absolute">
              <span className="ml-3 mt-1.5 rounded-2xl border border-gray-200 bg-[#f0f0f0] px-2 py-0.5 text-xs font-semibold text-[#757575]">
                {t('product.life_badge')}
              </span>
            </div>
          </>
        )}
        <TopChoiceBadge data={selectedProduct} />
        {showBadges && ( //Statik, attribute'a bağlanıp dinamik hale getirilebilir.
          <div className="h- absolute bottom-10 left-3 hidden w-9 items-center justify-center rounded-full border border-gray-300 bg-gray-300">
            <Image
              src={LactoseFreeIcon}
              height={24}
              width={24}
              alt={`lacoste-icon`}
            />
          </div>
        )}
      </>
    );
  return (
    <>
      {showBadges && ( //Statik, attribute'a bağlanıp dinamik hale getirilebilir.
        <>
          <div className="bottom-2.5 mt-1.5 hidden pb-0.5 text-left lg:absolute">
            <span className="ml-3 mt-1.5 rounded-2xl border border-gray-200 bg-[#f0f0f0] px-2 py-0.5 text-xs font-semibold text-[#757575]">
              {t('product.life_badge')}
            </span>
          </div>
        </>
      )}

      {showBadges && ( //Statik, attribute'a bağlanıp dinamik hale getirilebilir.
        <div className="h- absolute bottom-10 left-3 hidden w-9 items-center justify-center rounded-full border border-gray-300 bg-gray-300">
          <Image
            src={LactoseFreeIcon}
            height={24}
            width={24}
            alt={`lacoste-icon`}
          />
        </div>
      )}
      <TopChoiceBadge data={selectedProduct} />
      <Badge
        showBadges={showBadges}
        theme={theme}
        selectedProduct={selectedProduct}
      />
    </>
  );
};
