export const HoverWrapper = (props) => {
  const { children, setHover } = props;

  return (
    <div
      className="relative overflow-hidden"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
    </div>
  );
};
