'use client';

import {
  basketApi,
  useGetBasketQuery,
  useUpdateQuantityMutation
} from '@akinon/next/data/client/basket';
import { Button, Icon, LoaderSpinner } from '@theme/components';
import clsx from 'clsx';
import { useState, useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useAppDispatch } from '@akinon/next/redux/hooks';
import { getCookie } from '@akinon/next/utils';
import { useLocalization } from '@akinon/next/hooks';
import {
  checkThemeConflict,
  getCountryCode
} from '@theme/utils/shipment-type-controller';
import { motion, AnimatePresence } from 'framer-motion';
import { useWindowSize } from '@theme/hooks/use-window-size';

export const ProductCounter = (props) => {
  const {
    product,
    category,
    isAddToCartLoading,
    addToBasket,
    className,
    iconClassName,
    countClassName,
    recommendationItem,
    selectedProduct,
    isRecommendationItem,
    isFavoriteItem = false
  } = props;

  const [updateQuantityMutation] = useUpdateQuantityMutation();
  const { data } = useGetBasketQuery();
  const dispatch = useAppDispatch();
  const [reachedStock, setReachedStock] = useState(false);
  const [animate, setAnimate] = useState('animate-drop');
  const [isMaxStock, setIsMaxStock] = useState(false);
  const { t, locale } = useLocalization();
  const theme = getCookie('theme') || 'scheduled';
  const [themeConflict, setThemeConflict] = useState(false);
  const { width } = useWindowSize();
  const [removeProductLoader, setRemoveProductLoader] = useState(false);

  useEffect(() => {
    const shipmentType =
      selectedProduct?.attributes[`shipment_type_${getCountryCode(locale)}`];

    if (checkThemeConflict(theme, shipmentType)) {
      setThemeConflict(true);
    }
  }, [theme, selectedProduct]);

  const productBasketItem = useMemo(() => {
    return data?.basketitem_set
      ?.map((item) => item)
      .find(
        (item) =>
          item?.product?.pk ==
          (category == 'filter' ? recommendationItem?.pk : selectedProduct?.pk)
      );
  }, [product, data, selectedProduct]);

  const initialCount = productBasketItem
    ? Number(productBasketItem?.quantity)
    : 0;

  useEffect(() => {
    if (productBasketItem) {
      if (productBasketItem?.quantity == productBasketItem?.stock) {
        setReachedStock(true);
      }
    } else {
      setReachedStock(false);
    }
  }, [productBasketItem]);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleClickedAddToBasket = async () => {
    setIsMaxStock(true);
    await delay(3000);
    setAnimate('animate-rise');
    await delay(300);
    setIsMaxStock(false);
    setAnimate('animate-drop');
  };

  useEffect(() => {
    if (productBasketItem) {
      if (productBasketItem?.quantity == productBasketItem?.stock) {
        setReachedStock(true);
      } else {
        setReachedStock(false);
      }
    } else {
      setReachedStock(false);
    }
  }, [productBasketItem]);

  const updateQuantity = async (
    productPk: number,
    quantity: number,
    attributes: object = {}
  ) => {
    const bc = new BroadcastChannel('preorder_channel');
    bc.postMessage('update_preorder');
    const requestParams: any = {
      product: productPk,
      quantity,
      attributes: productBasketItem?.attributes || attributes,
      namespace: getCookie('theme') || 'scheduled'
    };

    setRemoveProductLoader(true);

    await updateQuantityMutation(requestParams)
      .unwrap()
      .then((data) => {
        dispatch(
          basketApi?.util?.updateQueryData(
            'getBasket',
            undefined,
            (draftBasket) => {
              Object.assign(draftBasket, data?.basket);
            }
          )
        );
        setRemoveProductLoader(false);
      })
      .catch(() => setRemoveProductLoader(false));
  };

  return (
    <>
      <div className="mt-auto">
        <div
          className={clsx(
            'flex w-full items-center justify-between gap-3',
            width >= 1024 && width < 1440 && '!gap-1',
            className
          )}
        >
          <AnimatePresence>
            {initialCount > 0 && (
              <>
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3 }}
                >
                  <Button
                    disabled={
                      initialCount === 0 ||
                      isAddToCartLoading ||
                      themeConflict ||
                      removeProductLoader
                    }
                    onClick={() => {
                      if (initialCount > 0) {
                        updateQuantity(
                          category == 'filter'
                            ? recommendationItem?.pk
                            : selectedProduct?.pk,
                          initialCount - 1
                        );
                      }
                    }}
                    className={twMerge(
                      clsx(
                        '!h-7 !w-7 border-gray-300 bg-[#f1f1f1] p-0 text-base !leading-[1.45rem] text-gray-900 hover:border-gray-300 hover:text-gray-900',
                        iconClassName,
                        themeConflict &&
                          'cursor-not-allowed hover:bg-[#f1f1f1]',
                        width >= 1024 && width < 1440 && '!h-6 !w-6 text-sm'
                      )
                    )}
                  >
                    {removeProductLoader ? (
                      <LoaderSpinner className="h-4 w-4 border-gray-380 border-t-transparent" />
                    ) : initialCount === 1 ? (
                      <Icon name="trash-2" />
                    ) : (
                      <>-</>
                    )}
                  </Button>
                </motion.div>
                <motion.span
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3 }}
                  className={twMerge(
                    clsx(
                      'rounded-[14px] bg-[#F9F9F9] px-8 py-[5px] text-lg leading-[1]',
                      countClassName,
                      width >= 1024 && width < 1440 && 'px-4 !text-base',
                      isFavoriteItem && width < 1024 && 'px-4',
                    )
                  )}
                >
                  {initialCount}
                </motion.span>
              </>
            )}
          </AnimatePresence>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <Button
              disabled={
                themeConflict || isAddToCartLoading || removeProductLoader
              }
              className={twMerge(
                clsx(
                  '!h-7 !w-7 p-0 text-base !leading-[1.4rem]',
                  iconClassName,
                  themeConflict &&
                    'cursor-not-allowed hover:bg-primary hover:text-white',
                  width >= 1024 && width < 1440 && '!h-6 !w-6 text-sm'
                )
              )}
              onClick={() => {
                if (reachedStock) {
                  handleClickedAddToBasket();
                } else {
                  addToBasket();
                }
              }}
            >
              {isAddToCartLoading ? (
                <LoaderSpinner className="h-4 w-4 lg:border-white lg:border-t-transparent lg:hover:border-primary lg:hover:border-t-transparent" />
              ) : (
                <>+</>
              )}
            </Button>
          </motion.div>
        </div>
      </div>
      {isMaxStock && (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={twMerge(
            'text-[0.6875rem] font-semibold leading-tight text-error transition-all duration-300',
            isRecommendationItem ? 'ml-2' : '',
            animate
          )}
        >
          {t('product.max_stock')}
        </motion.span>
      )}
    </>
  );
};
