import { useLocalization } from '@akinon/next/hooks';
import { swanProductClicked } from '@theme/utils/swan';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const Wrapper = (props) => {
  const { className, children, reachedStock } = props;
  const { t } = useLocalization();
  const [errorData, setErrorData] = useState({
    ...reachedStock
  });
  const [animate, setAnimate] = useState('animate-drop');

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleClickedAddToBasket = async () => {
    await delay(3000);
    setAnimate('animate-rise');
    await delay(300);
    setErrorData({
      pk: null,
      value: false
    });
    setAnimate('animate-drop');
  };

  useEffect(() => {
    setErrorData({
      ...reachedStock
    });
    handleClickedAddToBasket();
  }, [reachedStock]);

  const handleClick = () => {
    swanProductClicked(props?.product);
  };

  return (
    <div
      className={clsx(
        'rounded-[32px] border border-[#d9d9d9] px-3 pb-4 pt-3',
        className
      )}
      onClick={handleClick}
    >
      {children}

      {errorData && errorData?.value && (
        <span
          className={twMerge(
            'mt-2.5 text-[0.6875rem] font-semibold leading-tight text-error',
            animate
          )}
        >
          {t('product.max_stock')}
        </span>
      )}
    </div>
  );
};
