'use client';

import { skipToken } from '@reduxjs/toolkit/query';
import {
  useEffect,
  useMemo,
  useState,
  Children,
  cloneElement,
  ReactNode,
  Dispatch,
  SetStateAction,
  useCallback
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useGetProductByParamsQuery } from '@akinon/next/data/client/product';
import Settings from '../../settings';
import { useAddProductToBasket } from 'hooks';
import { Images } from './components/images';
import { Prices } from './components/prices';
import { Badges } from './components/badges';
import { Description } from './components/description';
import { Add } from './components/add';
import { Wrapper } from './components/wrapper';
import { Favorite } from './components/favorite';
import { Offers } from './components/offers';
import { SelectBox } from './components/select';
import { Product } from '@akinon/next/types';
import { ProductCounter } from './components/product-counter';
import { ProductContentBadge } from './components/product-content-badge';
import { HoverWrapper } from './components/hover-wrapper';
import { getCookie } from '@akinon/next/utils';
import { trackAddToCart } from '@theme/utils/emarsys';
import { useSession } from 'next-auth/react';
import { swanPushAddToCart } from '@theme/utils/swan';

interface Props {
  product: Product;
  width?: number;
  height?: number;
  index: number;
  showBadges?: boolean;
  isHoverable?: boolean;
  isNameMultiline?: boolean;
  fetchMode?: 'hover' | 'visible' | 'none';
  children?: ReactNode | ReactNode[];
  containerClassName?: string; // For Category Recommendation Content Component bkz. /CategoryRecommendationContent.tsx -> 121. satır
  setViewedProducts?: Dispatch<SetStateAction<Product[]>>;
  onAddToCartSuccess?: (pk?: number) => void;
}

const ProductItem = (props: Props) => {
  const {
    product,
    index,
    showBadges = true,
    isHoverable = true,
    isNameMultiline = false,
    children,
    fetchMode = 'none',
    containerClassName,
    setViewedProducts,
    onAddToCartSuccess
  } = props;

  const [selectedProduct, setProduct] = useState(product);
  const [error, setError] = useState(null);
  const [isHover, setHover] = useState(false);
  const [viewed, setViewed] = useState(false);
  const { ref, inView } = useInView();
  const [addProduct, { isLoading: isAddToCartLoading }] =
    useAddProductToBasket();
  const [theme, setTheme] = useState('default');
  const { data: session } = useSession();
  const [reachedStock, setReachedStock] = useState({
    pk: null,
    value: false
  });

  useEffect(() => {
    const storedTheme = getCookie('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  const pk = product.pk;

  const colorVariantKey = useMemo(
    () =>
      Settings.commonProductAttributes.find(
        (attribute) => attribute.translationKey === 'color'
      )?.key,
    []
  );
  const sizeVariantKey = useMemo(
    () =>
      Settings.commonProductAttributes.find(
        (attribute) => attribute.translationKey === 'size'
      )?.key,
    []
  );

  const [selectedOptions, setSelectedOptions] = useState(() => ({
    ...(product.attributes[colorVariantKey] && {
      [colorVariantKey]: product.attributes[colorVariantKey]
    })
  }));

  const { data, isFetching, isSuccess } = useGetProductByParamsQuery(
    isFetchable(fetchMode, isHoverable, isHover, viewed)
      ? { pk: product.pk, ...selectedOptions }
      : skipToken
  );

  const variants = useMemo(
    () => (isSuccess ? data?.variants : product?.extra_data?.variants),
    [data, isSuccess, product?.extra_data?.variants]
  );

  const colorVariants = useMemo(
    () =>
      variants?.find((variant) => variant.attribute_key === colorVariantKey)
        ?.options ?? [],
    [variants, colorVariantKey]
  );

  const sizeVariants = useMemo(
    () =>
      variants?.find((variant) => variant.attribute_key === sizeVariantKey)
        ?.options ?? [],
    [variants, sizeVariantKey]
  );

  useEffect(() => {
    if (!viewed && inView) {
      setViewed(true);
      setViewedProducts && setViewedProducts((prev) => [...prev, product]);
    }
  }, [inView, viewed, product, setViewedProducts]);

  useEffect(() => {
    setError('');
  }, [selectedOptions]);

  const onHover = useCallback(
    (status) => {
      if (isHoverable) {
        setHover(status);
      }
    },
    [isHoverable]
  );

  const selectAttribute = useCallback(
    (attribute_key, value) => {
      setSelectedOptions((prevOptions) => {
        const newSelectedOptions = { ...prevOptions };
        if (attribute_key === colorVariantKey) {
          delete newSelectedOptions[sizeVariantKey];
        }
        newSelectedOptions[attribute_key] = value;
        return newSelectedOptions;
      });
    },
    [colorVariantKey, sizeVariantKey]
  );

  const addToBasket = useCallback(() => {
    const requestParams: any = {
      product: selectedProduct.pk,
      quantity: 1,
      attributes: {},
      namespace: getCookie('theme') || 'scheduled'
    };

    addProduct(requestParams)
      .then(() => {
        onAddToCartSuccess?.(selectedProduct.pk);
        trackAddToCart(selectedProduct, session);
        swanPushAddToCart(selectedProduct);
        setReachedStock({
          pk: null,
          value: false
        });
      })
      .catch((error) => {
        if (error?.status == 406) {
          setReachedStock({
            pk: selectedProduct?.pk,
            value: true
          });
        }
        setError(
          error?.data?.non_field_errors ||
            Object.keys(error?.data).map(
              (key) => `${key}: ${error?.data[key].join(', ')}`
            )
        );
      });
  }, [addProduct, onAddToCartSuccess, selectedProduct.pk]);

  const passPropsToChildren = (children) => {
    return Children.map(children, (child) => {
      const hasChildren = !!child.props?.children;

      if (
        [
          Images,
          Prices,
          Badges,
          Description,
          Add,
          Wrapper,
          Favorite,
          Offers,
          ProductContentBadge,
          SelectBox,
          ProductCounter,
          HoverWrapper
        ].includes(child.type)
      ) {
        return cloneElement(child, {
          ...{
            ...props,
            pk,
            selectedProduct,
            error,
            isFetching,
            isHover,
            isHoverable,
            isSuccess,
            isNameMultiline,
            isAddToCartLoading,
            showBadges,
            index,
            colorVariantKey,
            sizeVariantKey,
            sizeVariants,
            colorVariants,
            addToBasket,
            selectAttribute,
            setProduct,
            theme,
            reachedStock,
            setHover: onHover
          },
          ...child.props,
          ...(hasChildren && {
            children: passPropsToChildren(child.props.children)
          })
        });
      } else if (hasChildren) {
        return cloneElement(child, {
          ...child.props,
          children: passPropsToChildren(child.props.children)
        });
      }

      return child;
    });
  };

  return <div ref={ref} className={containerClassName}>{passPropsToChildren(children)}</div>;
};

const isFetchable = (fetchMode, isHoverable, isHover, viewed) => {
  switch (fetchMode) {
    case 'hover':
      return isHoverable && isHover;
    case 'visible':
      return viewed;
    default:
      return false;
  }
};

ProductItem.Description = Description;
ProductItem.Images = Images;
ProductItem.Prices = Prices;
ProductItem.Badges = Badges;
ProductItem.Add = Add;
ProductItem.Wrapper = Wrapper;
ProductItem.Favorite = Favorite;
ProductItem.Offers = Offers;
ProductItem.Select = SelectBox;
ProductItem.ProductCounter = ProductCounter;
ProductItem.ProductContentBadge = ProductContentBadge;
ProductItem.HoverWrapper = HoverWrapper;

export { ProductItem };
