'use client';

import { Offer } from '@theme/components/offer';
import clsx from 'clsx';
import { Price } from 'components';
import { twMerge } from 'tailwind-merge';

export const Prices = (props) => {
  const {
    selectedProduct: { retail_price, price },
    product: { currency_type },
    priceClassName,
    retailPriceClassName,
    priceWrapperClassName,
    hidePrice,
    moveToBag
  } = props;

  return (
    <div
      className={`flex items-center justify-start gap-1.5 ${hidePrice && 'hidden'}`}
    >
      <div
        className={twMerge(
          clsx(
            'flex flex-wrap items-center lg:flex-nowrap',
            priceWrapperClassName
          )
        )}
      >
        <Price
          value={price}
          data-testid="product-price"
          className={twMerge(
            clsx(
              'text-base font-bold leading-[1.5] -tracking-[0.48px] text-black',
              moveToBag && 'text-[0.625rem] lg:text-base',
              priceClassName
            )
          )}
          currencyCode={currency_type}
        />

        {parseFloat(retail_price) !== parseFloat(price) && (
          <Price
            value={retail_price}
            className={twMerge(
              clsx(
                'text-sm font-normal leading-[1.43] text-error-400 line-through lg:leading-[1.25]',
                retailPriceClassName
              )
            )}
            currencyCode={currency_type}
          />
        )}
      </div>
      <Offer price={price} retailPrice={retail_price} />
    </div>
  );
};
