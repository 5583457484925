import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { Button, LoaderSpinner } from '../../../components';
import { getCookie } from '@akinon/next/utils';
import {
  basketApi,
  useGetBasketQuery,
  useUpdateQuantityMutation
} from '@akinon/next/data/client/basket';
import { useEffect, useMemo, useState } from 'react';
import {
  checkThemeConflict,
  getCountryCode
} from '@theme/utils/shipment-type-controller';
import { useLocalization } from '@akinon/next/hooks';
import { useAppDispatch } from '@akinon/next/redux/hooks';

export const Add = (props) => {
  const {
    product,
    category,
    isAddToCartLoading,
    addToBasket,
    className,
    iconClassName,
    countClassName,
    recommendationItem,
    selectedProduct,
    isRecommendationItem
  } = props;
  const [updateQuantityMutation] = useUpdateQuantityMutation();
  const { data } = useGetBasketQuery();
  const dispatch = useAppDispatch();
  const [reachedStock, setReachedStock] = useState(false);
  const [animate, setAnimate] = useState('animate-drop');
  const [isMaxStock, setIsMaxStock] = useState(false);
  const { t, locale } = useLocalization();
  const theme = getCookie('theme') || 'scheduled';
  const [themeConflict, setThemeConflict] = useState(false);

  useEffect(() => {
    const shipmentType =
      selectedProduct?.attributes[`shipment_type_${getCountryCode(locale)}`];

    if (checkThemeConflict(theme, shipmentType)) {
      setThemeConflict(true);
    }
  }, [theme, selectedProduct]);

  const productBasketItem = useMemo(() => {
    return data?.basketitem_set
      ?.map((item) => item)
      .find(
        (item) =>
          item?.product?.pk ==
          (category == 'filter' ? recommendationItem?.pk : selectedProduct?.pk)
      );
  }, [product, data, selectedProduct]);

  const initialCount = productBasketItem
    ? Number(productBasketItem?.quantity)
    : 0;

  const [count, setCount] = useState<number>(initialCount);

  useEffect(() => {
    if (productBasketItem) {
      setCount(productBasketItem.quantity);
      if (productBasketItem?.quantity == productBasketItem?.stock) {
        setReachedStock(true);
      }
    } else {
      setCount(0);
      setReachedStock(false);
    }
  }, [productBasketItem]);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleClickedAddToBasket = async () => {
    setIsMaxStock(true);
    await delay(3000);
    setAnimate('animate-rise');
    await delay(300);
    setIsMaxStock(false);
    setAnimate('animate-drop');
  };

  useEffect(() => {
    if (productBasketItem) {
      if (productBasketItem?.quantity == productBasketItem?.stock) {
        setReachedStock(true);
      } else {
        setReachedStock(false);
      }
    } else {
      setReachedStock(false);
    }
  }, [productBasketItem]);

  const updateQuantity = async (
    productPk: number,
    quantity: number,
    attributes: object = {}
  ) => {
    const bc = new BroadcastChannel('preorder_channel');
    bc.postMessage('update_preorder');
    const requestParams: any = {
      product: productPk,
      quantity,
      attributes: productBasketItem?.attributes || attributes,
      namespace: getCookie('theme') || 'scheduled'
    };

    await updateQuantityMutation(requestParams)
      .unwrap()
      .then((data) =>
        dispatch(
          basketApi?.util?.updateQueryData(
            'getBasket',
            undefined,
            (draftBasket) => {
              Object.assign(draftBasket, data?.basket);
            }
          )
        )
      );
  };

  return (
    <>
      <div className="flex items-center justify-end pe-1.5 lg:hidden">
        <div className="flex items-center justify-center gap-2">
          <>
            <Button
              disabled={count === 0 || isAddToCartLoading || themeConflict}
              onClick={() => {
                if (count > 0) {
                  setCount(count - 1);
                  updateQuantity(
                    category == 'filter'
                      ? recommendationItem?.pk
                      : selectedProduct?.pk,
                    count - 1
                  );
                }
              }}
              className={twMerge(
                clsx(
                  'h-6 w-6 border-gray-300 bg-[#f1f1f1] p-0 text-base !leading-[1.45rem] text-gray-900 hover:border-gray-300 hover:text-gray-900',
                  iconClassName,
                  themeConflict && 'cursor-not-allowed hover:bg-[#f1f1f1]',
                  count === 0 && 'pointer-events-none'
                )
              )}
            >
              -
            </Button>
            <span
              className={twMerge(
                clsx(
                  'text-base leading-[1]',
                  {
                    'text-[#b4b4b4]': count === 0,
                    'text-black': count > 0
                  },
                  countClassName
                )
              )}
            >
              {count}
            </span>
          </>

          {isAddToCartLoading ? (
            <LoaderSpinner wrapperClassName="w-fit" className="h-6 w-6" />
          ) : (
            <Button
              disabled={themeConflict || isAddToCartLoading}
              className={twMerge(
                clsx(
                  'h-6 w-6 p-0 text-base !leading-[1.4rem]',
                  iconClassName,
                  themeConflict &&
                    'cursor-not-allowed hover:bg-primary hover:text-white'
                )
              )}
              onClick={() => {
                if (reachedStock) {
                  handleClickedAddToBasket();
                } else {
                  addToBasket();
                }
              }}
            >
              +
            </Button>
          )}
        </div>
      </div>
      {isMaxStock && (
        <div
          className={twMerge(
            'mt-3 text-[0.6875rem] font-semibold leading-tight text-error transition-all duration-300',
            isRecommendationItem ? 'ml-2' : '',
            animate
          )}
        >
          {t('product.max_stock')}
        </div>
      )}
    </>
  );
};
