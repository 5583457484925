import { twMerge } from 'tailwind-merge';
import useFavButton from '../../../hooks/use-fav-button';
import clsx from 'clsx';

export const Favorite = (props) => {
  const { pk, product, favIconClassName, size, className } = props;
  const { FavButton } = useFavButton(pk);

  return (
    <div className="absolute right-1 top-0 z-10">
      <FavButton
        size={size || 16}
        className={twMerge(clsx('absolute right-1.5 top-1 z-10'), className)}
        iconClassName={favIconClassName}
      />
    </div>
  );
};
