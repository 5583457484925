'use client';

import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useLocalization } from '@akinon/next/hooks';

export interface OfferProps {
  price: string;
  retailPrice: string;
  className?: string;
}

export const Offer = (props: OfferProps) => {
  const { t, locale } = useLocalization();
  const { className } = props;
  const price = parseFloat(props.price);
  const retailPrice = parseFloat(props.retailPrice);
  const discount = Math.round(((retailPrice - price) / retailPrice) * 100);
  const hasRetailPrice = price !== retailPrice;

  if (!hasRetailPrice) {
    return null;
  }

  if (locale.split('-')[1] == 'qa') {
    return null;
  }

  if (discount < 20 && locale.split('-')[1] == 'om') {
    return null;
  }

  return (
    <div
      className={twMerge(
        clsx(
          'flex items-center rounded-md bg-[#04a550] bg-opacity-10 px-2 text-sm font-medium leading-[1.5] text-[#04a550]'
        ),
        className
      )}
    >
      <span className="mr-0.5">{discount}%</span>
    </div>
  );
};
