import clsx from 'clsx';
import { ProductItem } from '..';

export const ProductItemDefault = (props) => {
  const {
    ImageWidth,
    ImageHeight,
    mobileImageWidth,
    mobileImageHeight,
    wrapperClassName,
    favIconClassName,
    product,
    hidePrice = false,
    isOrderAgain,
    isFavoriteItem = false
  } = props;

  const variants = product?.extra_data?.variants;

  const options = variants?.[0]?.options.map((option) => {
    if (
      Number(option?.product?.price) !== 0 ||
      option?.product?.price !== null ||
      option?.in_stock
    ) {
      return {
        value: option.product.pk,
        price: option.product.price,
        retailPrice: option.product.price * 2,
        label: option.label,
        product: option.product
      };
    }
  });

  return (
    <ProductItem {...props}>
      <ProductItem.Wrapper className={`${wrapperClassName} justify-start`}>
        <ProductItem.HoverWrapper>
          <ProductItem.Images
            width={ImageWidth}
            height={ImageHeight}
            mobileImageHeight={mobileImageHeight}
            mobileImageWidth={mobileImageWidth}
          />

          <ProductItem.Favorite favIconClassName={favIconClassName} />
          <ProductItem.Badges />
          <ProductItem.ProductContentBadge />
        </ProductItem.HoverWrapper>
        <div className="flex flex-col flex-1">
          <div className="mb-2 flex max-w-full flex-col justify-between overflow-hidden text-ellipsis lg:mb-[11px]">
            <ProductItem.Select
              wrapperClassName={`mt-3 lg:mt-2.5 pb-1 lg:pb-2.5 ${isOrderAgain || isFavoriteItem ? 'hidden' : ''}`}
            />
            <ProductItem.Description className="mb-2.5 mt-2" />
            <ProductItem.Prices
              priceWrapperClassName="flex-col items-start min-h-[48px]"
              hidePrice={hidePrice}
            />
          </div>

          <ProductItem.Offers />
          <ProductItem.ProductCounter isFavoriteItem />
        </div>
      </ProductItem.Wrapper>
    </ProductItem>
  );
};
