'use client';

import { useLocalization } from '@akinon/next/hooks';

export const Offers = (props) => {
  const { showBadges, product } = props;

  const { t } = useLocalization();

  const offerVisible = Array.isArray(product?.basket_offers) && product?.basket_offers?.length ? true : false;

  return (
    <>
      {showBadges && (
        offerVisible && product.basket_offers.map((offer, index) => (
          <div key={index} className={`text-primary text-sm mb-2 lg:mb-2.5 lg:text-base ${offerVisible ? "visible opacity-100" : "opacity-0 invisible"}`}>
            {offer?.label}
          </div>
        ))
      )}
    </>
  );
};
