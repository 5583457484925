'use client';
import LactoseFreeIcon from '@theme/assets/icons/badge-lactose-free.svg';
import GlutenFreeIcon from '@theme/assets/icons/badge-gluten-free.svg';
import VeganIcon from '@theme/assets/icons/badge-vegan.svg';
import SugarFreeIcon from '@theme/assets/icons/badge-sugar-free.svg';
import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

/* prettier-ignore */
const specialityIcons = {
  "gluten free": GlutenFreeIcon,
  "vegan": VeganIcon,
  "sugar free": SugarFreeIcon,
  "lactose free": LactoseFreeIcon,
  "organic": GlutenFreeIcon,        // TODO: replace with the correct icons later
  "plant based": GlutenFreeIcon,    // TODO: replace with the correct icons later
  "eco friendly": GlutenFreeIcon    // TODO: replace with the correct icons later
};

const BadgeComponent = (props) => {
  const [hoverBadge, setHoverBadge] = useState<Boolean>(false);

  const { text, icon } = props;

  return (
    <div className="hidden items-center justify-end space-x-2 lg:flex">
      <span
        className={twMerge(
          clsx(
            'invisible rounded-full border border-gray-300 bg-white px-1 py-0.5 text-[10px] font-semibold text-black opacity-0 transition-opacity',
            {
              'visible opacity-100': hoverBadge
            }
          )
        )}
      >
        {text}
      </span>
      <div
        className={twMerge(
          clsx(
            'flex items-center justify-center rounded-full bg-gray-200 transition-all',
            hoverBadge ? 'h-8 w-8' : 'h-6 w-6'
          )
        )}
        onMouseOver={() => setHoverBadge(true)}
        onMouseLeave={() => setHoverBadge(false)}
      >
        <Image
          className="object-contain"
          src={icon}
          height={hoverBadge ? 24 : 18}
          width={hoverBadge ? 24 : 18}
          alt={`${text}-icon`}
        />
      </div>
    </div>
  );
};

export const ProductContentBadge = (props) => {
  const { isHover } = props;
  const { speciality_1, speciality_2, speciality_3, speciality_4 } =
    props.product.attributes;
  const specialityBadges = [
    speciality_1,
    speciality_2,
    speciality_3,
    speciality_4
  ];

  return (
    <div
      className={`absolute bottom-1 right-4 transform transition-all duration-500 ${
        isHover
          ? 'visible translate-x-0 opacity-100'
          : 'invisible translate-x-full opacity-0'
      }`}
    >
      <div className="flex flex-col gap-2">
        {specialityBadges.map((speciality, index) => {
          if (!speciality) return null;
          return (
            <BadgeComponent
              key={index}
              text={speciality}
              icon={specialityIcons[speciality.toLowerCase()]}
            />
          );
        })}
      </div>
    </div>
  );
};
